import { createRouter, createWebHistory } from 'vue-router';
import { useNProgress } from '@vueuse/integrations/useNProgress';
import 'nprogress/nprogress.css';
import {
  authRouteGuard,
  checkoutAuthRouteGuard,
  orderConfirmationGuard,
  giftAuthRouteGuard,
  pastaNowNameGuard,
  myBasketRouteGuard,
} from './utils/navigation';
import useBasketStore from './stores/basketStore';
import usePastaNowBasket from './stores/pastaNowBasketStore';

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && to.hash !== '#modal') {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes: [
    {
      path: '/',
      redirect: '/pasta-now-search-address',
    },
    {
      path: '/rate-my-takeaway',
      name: 'rate-takeaway-root',
      component: () => import('@/views/RateTakeawayRoot.vue'),
      children: [
        {
          path: '',
          component: () => import('@/views/RateTakeaway.vue'),
          name: 'rate-my-takeaway',
        },
        {
          path: 'confirmation',
          component: () => import('@/components/RateMyTakeawayConfirmation.vue'),
          name: 'rate-my-takeaway-confirmation',
          beforeEnter: (to, __, next) => {
            if (!to.query.rating) next('/rate-my-takeaway');
            next();
          },
        },
      ],
    },
    {
      path: '/social-login',
      component: () => import('@/views/SocialLogin.vue'),
      name: 'social-login',
      beforeEnter: (to, __, next) => {
        authRouteGuard(to, next);
      },
    },
    {
      path: '/signup',
      component: () => import('@/views/Signup.vue'),
      name: 'signup',
      beforeEnter: (to, __, next) => {
        authRouteGuard(to, next);
      },
    },
    {
      path: '/login',
      component: () => import('@/views/LoginScreen.vue'),
      name: 'login',
      beforeEnter: (to, __, next) => {
        authRouteGuard(to, next);
      },
    },
    {
      path: '/forgot-password',
      component: () => import('@/views/PasswordRecover.vue'),
      name: 'password-recover',
      beforeEnter: (to, __, next) => {
        authRouteGuard(to, next);
      },
    },
    {
      path: '/menu',
      name: 'product-box',
      component: () => import('@/views/ProductsPage.vue'),
      beforeEnter: (to, from, next) => {
        if (!from.matched.length) {
          to.meta.showBanner = true;
        }

        next();
      },
    },
    {
      path: '/products',
      redirect: '/menu',
    },
    {
      path: '/checkout',
      component: () => import('@/views/Checkout.vue'),
      beforeEnter: (to, __, next) => {
        checkoutAuthRouteGuard(to, next);
      },
      children: [
        {
          path: 'continue',
          component: () => import('@/views/SocialLogin.vue'),
          name: 'checkout-social-login',
        },
        {
          path: 'signup',
          component: () => import('@/views/Signup.vue'),
          name: 'checkout-signup',
        },
        {
          path: 'login',
          component: () => import('@/views/LoginScreen.vue'),
          name: 'checkout-login',
        },
        {
          path: 'delivery/address',
          component: () => import('@/views/DeliveryAddress.vue'),
          name: 'delivery-address',
          props: route => ({ isOpen: !!route.params.isOpen }),
        },
        {
          path: 'billingaddresses',
          component: () => import('@/views/BillingAddress.vue'),
          name: 'checkout-billing-address',
          props: route => ({ isOpen: !!route.params.isOpen }),
        },
        {
          path: 'delivery/instructions',
          component: () => import('@/views/DeliveryInstructions.vue'),
          alias: ['delivery/', '/checkout'],
          name: 'delivery-instructions',
          beforeEnter: async (_, __, next) => {
            const basketStore = useBasketStore();
            // We need to wait for the basket to be fully loaded
            while (!basketStore.isBasketLoaded) {
              await new Promise(resolve => setTimeout(resolve, 100));
            }
            if (basketStore.hasValidAddress) {
              next();
            } else next({ name: 'delivery-address' });
          },
        },
        {
          path: 'payment',
          component: () => import('@/views/CheckoutPayment.vue'),
          name: 'checkout-payment',
          beforeEnter: (_, from, next) => {
            const basketStore = useBasketStore();
            if (from.name === 'delivery-instructions' || from.name === 'checkout-billing-address') {
              next();
            } else {
              if (from.name === 'checkout-confirmation') {
                next('/');
                return;
              }
              if (!basketStore.hasValidAddress) {
                next({ name: 'delivery-address' });
              } else {
                next({ name: 'delivery-instructions' });
              }
            }
          },
        },
        {
          path: 'confirmation',
          component: () => import('@/views/CheckoutConfirmation.vue'),
          name: 'checkout-confirmation',
          beforeEnter: (_, from, next) => {
            orderConfirmationGuard(from, next);
          },
        },
        {
          path: 'error',
          component: () => import('@/views/CheckoutFail.vue'),
          name: 'checkout-fail',
          beforeEnter: (_, from, next) => {
            if (from.name !== 'checkout-payment') {
              next('/checkout');
            } else {
              next();
            }
          },
        },
      ],
    },
    {
      path: '/one-off/menu',
      name: 'one-off-product-box',
      component: () => import('@/views/ProductsPage.vue'),
      beforeEnter: (to, from, next) => {
        authRouteGuard(to, next);
      },
    },
    {
      path: '/gift-boxes',
      name: 'gift-boxes-product-box',
      component: () => import('@/views/GiftBoxes.vue'),
    },
    {
      path: '/pasta-now-search-address',
      name: 'pasta-now-search-address',
      component: () => import('@/views/PastaNowAddressSearch.vue'),
    },
    {
      path: '/pasta-now-find',
      name: 'pasta-now-find',
      component: () => import('@/views/PastaNowRestaurantFind.vue'),
    },
    {
      path: '/one-off',
      component: () => import('@/views/Checkout.vue'),
      beforeEnter: (to, __, next) => {
        giftAuthRouteGuard(to, next);
      },
      children: [
        {
          path: 'continue',
          component: () => import('@/views/SocialLogin.vue'),
          name: 'gift-social-login',
        },
        {
          path: 'signup',
          component: () => import('@/views/Signup.vue'),
          name: 'gift-signup',
        },
        {
          path: 'login',
          component: () => import('@/views/LoginScreen.vue'),
          name: 'gift-login',
        },
      ],
    },
    {
      path: '/one-off/checkout',
      component: () => import('@/views/Checkout.vue'),
      beforeEnter: (to, __, next) => {
        checkoutAuthRouteGuard(to, next, 'one-off');
      },
      children: [
        {
          path: 'delivery/address',
          component: () => import('@/views/DeliveryAddress.vue'),
          name: 'one-off-delivery-address',
          props: route => ({ isOpen: !!route.params.isOpen }),
        },
        {
          path: 'billingaddresses',
          component: () => import('@/views/BillingAddress.vue'),
          name: 'one-off-checkout-billing-address',
          props: route => ({ isOpen: !!route.params.isOpen }),
        },
        {
          path: 'delivery/instructions',
          component: () => import('@/views/DeliveryInstructions.vue'),
          alias: ['delivery/', '/checkout'],
          name: 'one-off-delivery-instructions',
          beforeEnter: (to, __, next) => {
            const basketStore = useBasketStore();
            if (basketStore.hasValidAddress) {
              next();
            } else next({ name: 'one-off-delivery-address', query: { ...to.query } });
          },
        },
        {
          path: 'payment',
          component: () => import('@/views/CheckoutPayment.vue'),
          name: 'one-off-checkout-payment',
          beforeEnter: (_, from, next) => {
            if (
              from.name === 'one-off-delivery-instructions' ||
              from.name === 'one-off-checkout-billing-address'
            ) {
              next();
            } else {
              if (from.name === 'one-off-checkout-confirmation') {
                next('/');
                return;
              }
              const basketStore = useBasketStore();
              if (!basketStore.hasValidAddress) {
                next({ name: 'one-off-delivery-address' });
              } else {
                next({ name: 'one-off-delivery-instructions' });
              }
            }
          },
        },
        {
          path: 'confirmation',
          component: () => import('@/views/CheckoutConfirmation.vue'),
          name: 'one-off-checkout-confirmation',
          beforeEnter: (_, from, next) => {
            orderConfirmationGuard(from, next);
          },
        },
        {
          path: 'error',
          component: () => import('@/views/CheckoutFail.vue'),
          name: 'one-off-checkout-fail',
          beforeEnter: (_, from, next) => {
            if (from.name !== 'one-off-checkout-payment') {
              next('/one-off/checkout');
            } else {
              next();
            }
          },
        },
      ],
    },
    {
      path: '/pasta-type',
      name: 'pasta-type',
      component: () => import('@/views/PastaType.vue'),
    },
    {
      path: '/pasta-now',
      name: 'pasta-now',
      component: () => import('@/views/PastaNowProductPage.vue'),
    },
    {
      path: '/takeaway/track/:orderId',
      name: 'pasta-now-confirmation',
      component: () => import('@/views/OrderConfirmation.vue'),
    },
    {
      path: '/pasta-now-checkout',
      name: 'pasta-now-checkout',
      component: () => import('@/views/PastaNowCheckout.vue'),
      beforeEnter: (to, __, next) => {
        checkoutAuthRouteGuard(to, next, 'pasta-now');
      },
      children: [
        {
          path: 'continue',
          component: () => import('@/views/SocialLogin.vue'),
          name: 'pasta-now-checkout-social-login',
        },
        {
          path: 'signup',
          component: () => import('@/views/Signup.vue'),
          name: 'pasta-now-checkout-signup',
        },
        {
          path: 'login',
          component: () => import('@/views/LoginScreen.vue'),
          name: 'pasta-now-checkout-login',
        },
        {
          path: 'delivery/address',
          component: () => import('@/views/PastaNowDeliveryAddress.vue'),
          name: 'pasta-now-delivery-address',
          props: route => ({ isOpen: !!route.params.isOpen }),
        },
        {
          path: 'delivery/instructions',
          component: () => import('@/views/PastaNowDeliveryInstructions.vue'),
          name: 'pasta-now-delivery-instructions',
          beforeEnter: (_, __, next) => {
            pastaNowNameGuard(next);
          },
        },
        {
          path: 'guest/email',
          component: () => import('@/views/GuestCheckoutEmail.vue'),
          name: 'pasta-now-guest-email',
        },
        {
          path: 'payment',
          component: () => import('@/views/PastaNowPayment.vue'),
          name: 'pasta-now-payment',
          beforeEnter: (_, from, next) => {
            const pastaNowBasketStore = usePastaNowBasket();
            if (
              from.name === 'pasta-now-delivery-instructions' ||
              pastaNowBasketStore.fulfillmentType === 'eat_in'
            ) {
              next();
            } else {
              next({ name: 'my-account-dashboard' });
            }
          },
        },
      ],
    },
    {
      path: '/paypal-confirmation',
      component: () => import('@/views/PaypalPaymentRedirect.vue'),
      name: 'paypal-confirmation',
    },
    {
      path: '/paypal-setup-confirmation',
      component: () => import('@/views/PaypalSetupPaymentRedirect.vue'),
      name: 'paypal-setup-confirmation',
    },
    {
      path: '/account',
      name: 'my-account',
      component: () => import('@/views/MyAccount.vue'),
      beforeEnter: (to, __, next) => {
        authRouteGuard(to, next);
      },
      children: [
        {
          path: 'dashboard',
          name: 'my-account-dashboard',
          alias: ['/account'],
          component: () => import('@/views/MyAccountDashboard.vue'),
          meta: {
            label: 'My account home',
            subheader:
              'Here you can see an overview of your plan and quickly access what you need.',
          },
        },
        {
          path: 'upcoming-orders',
          name: 'upcoming-orders',
          component: () => import('@/views/UpcomingOrders.vue'),
          meta: {
            label: 'Your pasta plan',
            subheader:
              'Here you can manage all of your upcoming deliveries, choose recipes, change delivery details or skip deliveries',
          },
        },
        {
          path: 'order-history',
          name: 'order-history',
          component: () => import('@/views/OrderHistory.vue'),
          meta: {
            label: 'Past orders',
            subheader:
              'Here you can view all of your past deliveries, rate your recipes and get help with a box.',
          },
        },

        {
          path: 'pasta-plan',
          name: 'pasta-plan',
          component: () => import('@/views/PastaPlan.vue'),
          meta: {
            label: 'Plan settings',
            subheader:
              'Here you can manage your plan settings, including your delivery and dietary preferences.',
          },
        },
        {
          path: 'rewards',
          name: 'rewards',
          component: () => import('@/views/Rewards.vue'),
          meta: {
            label: 'Offers',
            subheader:
              'Here you can access your rewards, see our fabulous partner offers, and earn free pasta.',
          },
          alias: ['offers'],
        },
        {
          path: 'my-details',
          name: 'details',
          component: () => import('@/views/MyDetails.vue'),
          meta: {
            label: 'Profile',
            subheader: 'Here you can manage your personal details.',
          },
        },
      ],
    },
    {
      path: '/order/:orderId',
      name: 'order',
      component: () => import('@/views/Order.vue'),
      beforeEnter: (to, __, next) => {
        authRouteGuard(to, next);
      },
    },
    {
      path: '/order/:orderId/edit',
      component: () => import('@/views/EditOrder.vue'),
      beforeEnter: (to, __, next) => {
        authRouteGuard(to, next);
      },
      name: 'upcoming-order-edit',
      children: [
        {
          path: 'delivery-info',
          component: () => import('@/components/UpComingOrderDeliveryDetails.vue'),
          name: 'upcoming-order-delivery-info',
        },
        {
          path: 'recipes',
          component: () => import('@/components/UpComingOrderProductBox.vue'),
          name: 'upcoming-order-edit-recipes',
        },
        {
          path: 'change-delivery-address',
          component: () => import('@/components/UpcomingOrderNewAddress.vue'),
          name: 'upcoming-order-edit-delivery-address',
        },
      ],
    },
    {
      path: '/schedule/:scheduleWeekId/edit',
      component: () => import('@/views/BoxDetails.vue'),
      name: 'scheduled-order-edit-recipes',
      beforeEnter: (to, __, next) => {
        authRouteGuard(to, next);
      },
    },
    {
      path: '/schedule/one-off/:orderId/view',
      component: () => import('@/views/OneOffUpcomingBoxDetails.vue'),
      name: 'schedule-one-off-order-view',
      beforeEnter: (to, __, next) => {
        authRouteGuard(to, next);
      },
    },
    {
      path: '/schedule/:scheduleWeekId/choose-recipes',
      component: () => import('@/components/UpComingOrderProductBox.vue'),
      name: 'schedule-week-no-order-pick-recipe',
      beforeEnter: (to, __, next) => {
        authRouteGuard(to, next);
      },
    },
    {
      path: '/schedule/:scheduleWeekId/change-delivery-date',
      component: () => import('@/views/ScheduleWeekChangeDeliveryDate.vue'),
      name: 'change-delivery-date-for-schedule-week',
      beforeEnter: (to, __, next) => {
        authRouteGuard(to, next);
      },
    },
    {
      path: '/payment/success',
      component: () => import('@/views/PaymentSuccessPage.vue'),
      name: 'payment-success',
      beforeEnter: (to, __, next) => {
        authRouteGuard(to, next);
      },
    },
    {
      path: '/payment/:orderId',
      component: () => import('@/views/PaymentPage.vue'),
      name: 'payment',
      beforeEnter: (to, __, next) => {
        authRouteGuard(to, next);
      },
    },
    {
      path: '/next-order-voucher',
      component: () => import('@/views/NextOrderVoucher.vue'),
      name: 'next-order-voucher',
      beforeEnter: (to, __, next) => {
        if (!to.query.vc) {
          next('/');
          return;
        }
        authRouteGuard(to, next);
      },
    },
    {
      path: '/auth-facebook-cb',
      component: () => import('@/views/FacebookRedirect.vue'),
      name: 'auth-facebook-cb',
    },
    {
      path: '/my-basket',
      name: 'my-pn-basket',
      component: () => import('@/components/GetMyBasketLoadingComponent.vue'),
      beforeEnter: (to, __, next) => {
        myBasketRouteGuard(to, next);
      },
    },
    {
      path: '/my-rk-basket',
      name: 'my-rk-basket',
      component: () => import('@/components/GetMyBasketLoadingComponent.vue'),
      beforeEnter: (to, __, next) => {
        myBasketRouteGuard(to, next);
      },
    },
    {
      path: '/support',
      component: () => import('@/views/Support.vue'),
      name: 'support',
    },
    {
      path: '/support-success',
      component: () => import('@/views/CustomerSupportSuccess.vue'),
      name: 'support-success',
    },
    {
      path: '/beta-opt-in',
      component: () => import('@/views/BetaOptIn.vue'),
      name: 'beta-opt-in',
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('@/views/404Page.vue'),
    },
    {
      path: '/:pathMatch(.*)',
      name: 'bad-not-found',
      component: () => import('@/views/404Page.vue'),
    },
  ],
});

useNProgress(null, { minimum: 0.1 });

router.beforeEach(to => {
  const { start } = useNProgress();
  start();
  let canonical = document.querySelector('link[rel="canonical"]');
  if (!canonical) {
    canonical = document.createElement('link');
    canonical.setAttribute('rel', 'canonical');
    document.head.appendChild(canonical);
  }
  canonical.setAttribute('href', `https://plan.pastaevangelists.com${to.path}`);
});

router.afterEach(() => {
  const { done } = useNProgress();
  done();
});

export default router;
